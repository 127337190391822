import {getAttribute, on} from '@acng/frontend-bounty';
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {defineController} from '@acng/frontend-stargazer';

const MODULE = 'layout/config/controller/on';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineController(':on', (element, eventType, scope) => {
  const listenerKey = getAttribute(element, `:on:${eventType}`) || eventType;

  /**
   * @type {?(ev: Event) => void}
   */
  const listener = scope?.[listenerKey];

  DEBUG: if (VERBOSE || element.hasAttribute('debug'))
    console.info(MODULE, {element, eventType, listenerKey, scope});

  if (listener) {
    ASSERT: typeguard(MODULE, listener, IS(Function));

    on(element, eventType, listener.bind(scope));
  }
});
