/** @module acng/games/widgets/link */
export const name = 'onswGamesLink';
export const type = 'component';
import {addClass, removeClass, on, off, toggleClass} from '@acng/frontend-bounty';
/** @type {angular.IComponentOptions} */
export const param = {
  template: '<a class="box" href="#/games"><span class="label">{{ \'games.games\' | translate }}</span><span class="value">{{ $ctrl.count }}</span></a>',
  /** @implements {angular.IController} */
  controller: class {
    static $inject = ['$scope', '$element', 'Widget', 'games', 'user', '$compile'];
    /**
     * @param {angular.IScope} $scope - listen to user login event
     * @param {JQuery} $element - required by {@link core.WidgetConstructor} and animation stuff
     * @param {import('acng/core/factory/widget').WidgetConstructor} Widget - error notification
     * @param {import('../factory/games').GamesService} games - load sessions
     * @param {import('acng/userPool/factory/user').User} user - check guest
     * @param {angular.ICompileService} $compile - compile animation element
     */
    constructor($scope, $element, Widget, games, user, $compile) {
      /**/
      let animationCount = 0;
      /**/
      let animationRunning = false;
      /**/
      const element = $element[0];
      const widget = new Widget($scope, $element, 'ons-item');
      on(element, 'transitionend', () => removeClass(element, 'wumper'));
      /**/
      this.count = 0;
      /**/
      this.$onInit = async () => {
        DEBUG: console.debug('games/widgets/link $onInit');
        if (!user.guest) {
          await games.getSessions();
          update();
          on(games, 'update', update);
        } else {
          const off = $scope.$on('userPool.login.success', async () => {
            off();
            await games.getSessions();
            update();
            on(games, 'update', update);
          });
        }
      };
      /**/
      this.$onDestroy = () => off(games, 'update', update);
      /** @param {Event} [evt] */
      const update = evt => {
        DEBUG: console.debug('games/widgets/link update');
        this.count = 0;
        for (const session of games.sessionOfAmateur.values()) {
          if (session.getState().isActive) {
            this.count++;
          }
        }
        toggleClass(element, 'empty', !this.count);
        $scope.$apply();
        const session = /** @type {import('./game').Session | undefined} */ (/** @type {CustomEvent | undefined} */(evt)?.detail?.session);
        const state = session?.getState();
        if (state && state.isActive && !state.userTurn && !state.amateurTurn) {
          increaseWithAnimation();
        } else if (this.count) {
          addClass(element, 'wumper');
        }
        /*
        off(games, 'update', update);
        try {
          for (const session of games.sessions.values()) {
            if (session.getState().hasTurn) {
              DEBUG: console.debug('games/widgets/link update session with turn', {session});
              if (this.count) {
                animationCount = -1;
              }
              increaseWithAnimation();
            }
          }
        } catch (reason) {
          widget.notify(reason);
        }
        on(games, 'update', update);
        */
      };
      /**/
      const increaseWithAnimation = () => {
        animationCount++;
        if (animationRunning) {
          return;
        }
        animationRunning = true;
        $compile('<span class="gain" ons-icon="::*,gameColor"></span>')($scope)
          .appendTo($element)
          .on('animationend', ev => {
            animationRunning = false;
            ev.stopPropagation();
            //$scope.$apply(() => (this.count = this.count + animationCount));
            DEBUG: console.debug('games/widgets/link animationend', this.count);
            if (ev.target instanceof HTMLElement) {
              ev.target.remove();
            }
            addClass(element, 'wumper');
          });
      };
    }
  },
};
