import {CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';
import {removeChildNodes, removeClass, addClass, HTMLElement} from '@acng/frontend-bounty';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {inject} from 'acng/core/service/ng.js';
import {livecamFeature} from '../config/feature.js';
import {LAYOUT_CLASS_ACTIVE, LAYOUT_CLASS_INACTIVE} from 'acng/layout/config/css-classes.js';
import {fskTrans} from 'acng/userPool/0fsk.js';

const MODULE = 'livecam/widgets/motto';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Verbose import', MODULE);

livecamFeature.defineElementLoader('onsw-livecam-motto', async (localName) => {
  const Livecam = inject('Livecam');
  const $sanitize = inject('$sanitize');

  ctxAmateur[CTX_SUBSCRIBE_TAGNAME](localName);

  return class extends HTMLElement {
    connectedCallback() {
      const element = this;

      ctxAmateur[CTX_OBSERVE](element, (amateur) => {
        removeChildNodes(element);
        removeClass(element, LAYOUT_CLASS_ACTIVE);
        addClass(element, LAYOUT_CLASS_INACTIVE);

        if (!amateur) {
          return;
        }

        const livecam = Livecam.get(amateur.id);
        if (!livecam) {
          return;
        }

        const text = fskTrans(livecam.motto);
        DEBUG: if (VERBOSE) console.debug('livecam/widget/motto', {amateur, livecam, text});
        if (!text) {
          return;
        }

        removeClass(element, LAYOUT_CLASS_INACTIVE);
        addClass(element, LAYOUT_CLASS_ACTIVE);
        element.innerHTML = $sanitize(text);
      });
    }
  };
});
