/**
 * @module Amateur´s Friends
 * @since 3.77.0
 * @author Jacob Viertel <jv@onscreen.net>
 * @author Markus Kirscht <markus.kirscht@iventuregroup.com>
 * @author Nils Engels <ne@onscreen.net>
 *
 * Display of a list of all friends of the local *Amateur Context*.
 *
 * @example
 * ```html
 * <onsw-amateur-friends class="warp-grid my-list-style">
 *   <template :id="item">
 *     <!-- preloaded <img> will be prepended here -->
 *     User name: ${nickname}
 *     Age of user: ${age}
 *   </template>
 *
 *   <template :id="empty">
 *     ${nickname} has no friends.
 *   </template>
 * </onsw-amateur-friends>
 * ```
 *
 * @todo
 * - BUG: the renderEmpty might be appended for the past amateur. 
 *   but the obvious fix is not desirable.
 *
 * @see [Warpdrive](https://docs.devac.de/discovery/)
 * @see [Amateur Context](../../amateurPool/context/amateur.js)
 */

import {Rendering, defineCustomWidget, defineScope} from '@acng/frontend-stargazer';
import {Warpdrive} from '@acng/frontend-discovery';
import {addClass, append, createDiv, loadImage} from '@acng/frontend-bounty';
import {CTX_OBSERVE, CTX_VALUE} from '@acng/frontend-relativity/minify';

import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {friendFeature} from '../config/feature.js';

import {asset, media} from 'acng/core/service/env.js';
import dummySrc from 'assets/basic/img/noImg.png';
import {AMATEUR_FRIENDS_DATA, typeguard} from '../service/typeguard.js';
import {cdn} from 'acng/core/service/backend.js';

const MODULE = 'friend/widget/amateur-friends';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`Import verbose ${MODULE}`);

(() => {
  defineScope('onsw-amateur-friends', ['nickname', 'age']);
  defineCustomWidget(
    friendFeature,
    'onsw-amateur-friends',

    /**
     * @extends {Warpdrive<AmateurFriendData, RenderingFriend, RenderingEmpty>}
     */
    class extends Warpdrive {
      link() {
        ctxAmateur[CTX_OBSERVE](this, () => this.warp());
      }

      /**
       * @param {number} offset
       * @param {RenderingEmpty} renderEmpty
       */
      async more(offset, renderEmpty) {
        const friends = await getAmateurFriends(this, offset);

        if (!offset && !friends.length) {
          append(
            this,
            renderEmpty.toElement(createDiv(), {nickname: ctxAmateur[CTX_VALUE](this)?.getNickname()})
          );
        }

        return friends;
      }

      /**
       * @param {AmateurFriendData} friend
       * @param {RenderingFriend} renderFriend
       * @param {import('@acng/frontend-discovery').Loader} loader
       */
      async render(friend, renderFriend, loader) {
        DEBUG: if (VERBOSE || this.hasAttribute('debug')) console.info(MODULE, {friend});

        const image = await preloadFriendsImage(friend);
        const {target} = loader;

        if (!target) {
          return;
        }

        renderFriend.toElement(target, friend);

        addClass(image, 'image');
        target.prepend(image);

        if (!friend.age) {
          renderFriend.nodes?.age?.parentElement?.remove();
        }
      }
    },
    [ctxAmateur]
  );

  /**
   * @todo
   * - [ ] Implement offset
   *
   * @param {HTMLElement} element
   * @param {number} [offset] - Not implemented in the backend, returns empty array if set to anything truthy.
   * @returns {Promise<AmateurFriendData[]>}
   */
  const getAmateurFriends = async (element, offset) => {
    const amateur = ctxAmateur[CTX_VALUE](element);

    ASSERT: if (!amateur) {
      console.error(MODULE, 'amateur is required', {element});
      throw Error();
    }

    if (offset) {
      return [];
    }

    const friends = await cdn(`friends/${amateur.id}`, element);

    ASSERT: typeguard(`${MODULE} friends`, friends, AMATEUR_FRIENDS_DATA());
    DEBUG: if (VERBOSE) console.info(MODULE, {amateur, friends});

    return friends;
  };

  /**
   * @param {AmateurFriendData} friend
   * @returns {Promise<HTMLImageElement>}
   */
  const preloadFriendsImage = async (friend) => {
    const fallbackSrc = asset(dummySrc);
    const imageSrc = friend.image_uri ? `${media.user}/${friend.image_uri}` : fallbackSrc;
    return await loadImage(imageSrc, fallbackSrc);
  };
})();

/**
 * Just like in the response from the ACNG public API GET "/api/friends/{amateurId}"
 *
 * @typedef AmateurFriendData
 * @property {string} nickname
 * @property {?number} age
 * @property {?string} image_uri
 */

/**
 * @typedef {Rendering<{nickname: string, age: ?number}, {age: Text}>} RenderingFriend
 */

/**
 * @typedef {import("@acng/frontend-stargazer").Rendering<{nickname?: string}, {}>} RenderingEmpty
 */
