/**
 * @module Set Attribute
 * @since 3.77.0
 * @author Jacob Viertel <jv@onscreen.net>
 *
 * Set an {@link !Attr} to the (string) value of a scope variable.
 */
import {getAttribute, setAttribute} from '@acng/frontend-bounty';
import {defineController} from '@acng/frontend-stargazer';

const MODULE = 'layout/config/controller/attr';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);


defineController(':attr', (element, attrName, scope) => {
  const attrValueKey = getAttribute(element, `:attr:${attrName}`) || attrName;
  const value = scope?.[attrValueKey];

  DEBUG: if (VERBOSE || element.hasAttribute('debug')) {
    console.info(MODULE, {element, attrName, attrValueKey, scope});
  }

  setAttribute(element, attrName, value);
});
