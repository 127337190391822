import {Promise} from '@acng/frontend-bounty';
import {ARRAY, BOOLEAN, ENUM, INTEGER, OBJECT, STRING, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {createElement, createGlobalContext} from '@acng/frontend-relativity';
import {CTX_OBSERVE, CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {inject} from 'acng/core/service/ng.js';
import {listen} from 'acng/core/context/event-bus.js';
import {ctxEventBusUser} from 'acng/userPool/context/event-bus-user.js';
import {TIMESTAMP_MS} from 'acng/core/service/typeguard.js';

type Amateur = import('acng/amateurPool/factory/Amateur').Amateur;

export const ctxPins = createGlobalContext(false);

/**
 * @deprecated Use byAmateur instead
 */
const pins: string[] & {changed?: number} = [];
pins.changed = 0;

const byAmateur = new Set<string>();
/**
 * @deprecated Implement something different
 */
let resolvePins: ((res: typeof pins) => void) | undefined;

/**
 * @deprecated
 * Required for the onsd-thing
 */
export const promise = new Promise((resolve: (value: typeof pins) => void) => {
  resolvePins = () => resolve(pins);
});

listen('pin', (data) => {
  ASSERT: typeguard('', data, EVENTBUS_PIN);
  const id = `${data.partner.id}`;

  if (data.status) {
    if (pins.indexOf(id) < 0) {
      pins.unshift(id);
      pins.changed!++;
      byAmateur.add(id);
      ctxPins[CTX_PROVIDE](null, true);
    }
  } else {
    const idx = pins.indexOf(id);
    if (idx >= 0) {
      pins.splice(idx, 1);
      pins.changed!++;
      byAmateur.delete(id);
      ctxPins[CTX_PROVIDE](null, true);
    }
  }
});

export const checkPin = (amateur: Amateur) => byAmateur.has(amateur.id);

export const checkPinById = (amateurId: string) => byAmateur.has(amateurId);

export const removePin = async (amateur: Amateur) => {
  const http = inject('http');
  await http().delete(`/api/pin/amateur/${amateur.id}`, {dontIntercept: true});
};

export const addPin = async (amateur: Amateur) => {
  const http = inject('http');
  await http().put(`/api/pin/amateur/${amateur.id}`, {}, {dontIntercept: true});
};

ctxEventBusUser[CTX_OBSERVE](createElement(), async (online) => {
  pins.length = 0;
  pins.changed!++;
  byAmateur.clear();

  if (!online) {
    return;
  }

  const http = inject('http');
  const res: angular.IHttpResponse<string[]> = await http().get('/api/pin/all', {dontIntercept: true});

  ASSERT: typeguard('pins', res.data, ARRAY(STRING));

  for (let i = 0; i < res.data.length; i++) {
    const id = res.data[i];
    byAmateur.add(id);
    pins.push(id);
  }
  resolvePins!(pins);
  ctxPins.provide(null, true);
});

const EVENTBUS_PIN = /* @__PURE__ */ (() => OBJECT({
  type: ENUM(/** @type {const} */ (['pin'])),
  partner: OBJECT({pool_id: INTEGER, id: STRING}),
  status: BOOLEAN,
  timestamp_ms: TIMESTAMP_MS(),
}))();
