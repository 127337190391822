/**
 * @module Friend Routes
 * @author Jacob Viertel <jv@onscreen.net>
 *
 * Two routes, both of which have a display in the main view.
 *
 * - **#/friends/{`friendStatus`}**
 *   Display friendships with given status of the `AuthUser`.
 *
 * - **#/sedcard/{`nickname`}/friends**
 *   Display friends of the `amateur` provided by the *Sedcard Route*.
 *
 * @see [AuthUser](../../userPool/context/auth-user.js)
 * @see [Sedcard Route](../../amateurPool/config/routes.js)
 * @see [Friends of AuthUser Template](../../../html/friend/user-friends.html)
 * @see [Friends of Amateur Template](../../../html/friend/amateur-friends.html)
 */

import {path, validate, on, middleware} from '@acng/frontend-voyager';

import {settingsBaseRoute} from 'acng/userPool/config/routes.js';
import {amateurRoute} from 'acng/amateurPool/config/routes.js';
import {mainView} from 'acng/layout/service/view-middleware.js';
import {friendFeature} from './feature.js';
import {inject} from 'acng/core/service/ng.js';
import {t} from 'acng/locale/config/translate.js';

settingsBaseRoute.create(
  path('/friends/{friendStatus}'),
  validate(({friendStatus}) =>
    /requested|pending|confirmed|denied/.test(/** @type {string} */ (friendStatus))
  ),
  middleware(async (next) => {
    next.friendsIntroText = await t(`friend.${next.friendStatus}_intro`).catch(() => next.friendStatus);
  }),
  on('enter', () => inject('user').trackActivity()),
  mainView(friendFeature, 'user-friends')
);

amateurRoute.create(
  path('/friends'), //
  mainView(friendFeature, 'amateur-friends')
);
