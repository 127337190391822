/**
 * @module Link to Friendships
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.77.0
 *
 * @todo
 * - This component should use a transcluded template.
 */

import {Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {attributeChangedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {ENUM, IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {
  append,
  cloneNode,
  createDiv,
  getDataAttribute,
  hasClass,
  noop,
  on,
  removeNode,
  setText,
  sizeOfArray,
  toggleClass,
  whenAll,
} from '@acng/frontend-bounty';

import {ctxFriendship, friendships} from '../context/friendships.js';
import {friendFeature} from '../config/feature.js';
import {t} from 'acng/locale/config/translate.js';
import {POSSIBLE_STATES, STATE_PENDING} from '../service/friendship.js';

const MODULE = 'friend/widget/link';

(() => {
  defineCustomWidget(
    friendFeature,
    'onsw-friendships-link',

    class FriendshipsLink extends Widget {
      /**
       * @type {string[]}
       */
      static t;

      static async setup() {
        this.t = await whenAll(POSSIBLE_STATES.map((s) => t(['friend', s]).catch(() => s)));
      }

      link() {
        ctxFriendship.observe(this, (friendship) => {
          const {nodes} = this;
          const type = getDataAttribute(this, 'type');
          const customLabel = getDataAttribute(this, 'label');

          ASSERT: {
            typeguard(MODULE, nodes, NODES);
            typeguard(MODULE, type, ENUM(POSSIBLE_STATES));
          }

          const href = `#/profile/friends/${type}`;
          const count = sizeOfArray(friendships[type]);

          nodes.a.href = href;
          if (customLabel) {
            t(customLabel)
              .then((text) => setText(nodes.label, text))
              .catch(noop);
          } else {
            setText(nodes.label, FriendshipsLink.t[POSSIBLE_STATES.indexOf(type)]);
          }
          setText(nodes.count, `${count}`);
          toggleClass(this, 'empty', !count);
          toggleClass(this, 'active', location.hash == href);

          if (hasClass(this, 'friends-button')) {
            if (friendship?.status == STATE_PENDING) {
              const element = createDiv('gain');
              on(element, ['animationend', 'animationcancel'], () => removeNode(element));
              append(element, cloneNode(nodes.animation.content));
              append(this, element);
            }
          }

          /*
          if (hasClass(this, 'friends-button')) {
            const that = this;
            (function testAnimation() {
              setTimeout(() => {
                console.warn('animation');
                const element = createDiv('gain');
                on(element, ['animationend', 'animationcancel'], () => removeNode(element));
                append(element, cloneNode(nodes.animation.content));
                append(that, element);
                testAnimation();
              }, Math.random() * 3000 + 100);
            })();
          }
          */
        });
      }

      [attributeChangedCallback] = FriendshipsLink.prototype.link;
    },
    [ctxFriendship]
  );
})();

const NODES = /* @__PURE__ */ (() =>
  OBJECT({
    label: IS(HTMLElement),
    count: IS(Text),
    a: IS(HTMLAnchorElement),
    animation: IS(HTMLTemplateElement),
  }))();
