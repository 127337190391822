import {addClass, isUndefined, removeClass} from '@acng/frontend-bounty';
import {defineController} from '@acng/frontend-stargazer';
import {STYLE_WAITING} from '../style.js';
import {IS, STRING, typeguard} from '@acng/frontend-bounty/typeguard.js';

const MODULE = 'layout/config/controller/src';
const VERBOSE = false;
const TEST_ADD_UNSAFE_RANDOM_DELAY = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineController(':src', (element, value, scope) => {
  ASSERT: typeguard(MODULE, element, IS(HTMLImageElement));

  if (isUndefined(scope) || isUndefined(scope[value])) {
    DEBUG: if (VERBOSE) console.debug(MODULE, 'not defined in scope', {element, value});
  } else {
    const src = scope[value];

    ASSERT: typeguard(MODULE, src, STRING);

    if (element.src == src) {
      DEBUG: if (VERBOSE) console.debug(MODULE, 'src is already set', {element, value, src});
    } else {
      DEBUG: if (VERBOSE) console.info(MODULE, 'set src', {element, value, src});
      addClass(element, STYLE_WAITING);
      element.onload = () => removeClass(element, STYLE_WAITING);
      if (!TEST_ADD_UNSAFE_RANDOM_DELAY) {
        element.src = src;
      } else {
        setTimeout(() => {
          element.src = src;
        }, (Math.random() * 60 + 10) ** 2);
      }
    }
  }
});
