/**
 * @module Conditional Rendering
 * @experimental
 * @author Jacob Viertel <jv@onscreen.net>
 */

import {defineController} from '@acng/frontend-stargazer';

const MODULE = 'layout/config/controller/if';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * @type {Record<string, Function>}
 */
const cache = {};
const matchNextScopeVariable = /(?:[^.\w]|^)(\w+)/g;

defineController(':if', (element, value, scope) => {
  if (!cache[value]) {
    /**
     * @type {string[]}
     */
    const vars = [];
    /**
     * @type {?RegExpExecArray}
     */
    let match;
    matchNextScopeVariable.lastIndex = 0;
    while ((match = matchNextScopeVariable.exec(value))) {
      vars.push(match[1]);
    }
    const uniqueVars = Array.from(new Set(vars)).join(',');
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, ':if', {uniqueVars, value});
    const fn = new Function(
      'scope',
      `"use strict";
      try {
        const { ${uniqueVars} } = scope;
        return !!( ${value} )
      } catch (reason) {
        console.error(':if', this, reason);
        return false;
      }`
    );
    /*
    const match = value.split('=>');
    const fn = new Function(
      'p',
      `"use strict"; try { const ${match[0]} = p; return !!(${match[1]}) } catch (reason) { return false }`
    );
    console.warn('IFMA created function', match[0], match[1]);
    */
    cache[value] = fn;
  }

  DEBUG: if (VERBOSE || element.hasAttribute('debug'))
    console.info(MODULE, ':if', {element, fn: cache[value], scope});

  if (!cache[value].call(element, scope)) {
    element.remove();
  }

  /*
  // idea: Proxy-Object für params + context
  console.warn('IFMA execute function', {element, ...params});
  const isTemplate = isInstanceOf(element, HTMLTemplateElement);
  if (cache[value].call(element, params)) {
    if (isTemplate) {
      element.content.firstElementChild?.setAttribute(':if', value);
      element.replaceWith(element.content);
    }
  } else if (!isTemplate) {
    const template = createElement(TAGNAME_TEMPLATE);
    template.setAttribute(':if', value);
    element.replaceWith(template);
    template.content.append(element);
  }
  */
});
