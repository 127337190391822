import {now} from '@acng/frontend-bounty/timing/now.js';
import {media} from 'acng/core/service/env.js';
import {inject} from 'acng/core/service/ng.js';

/**
 * @implements {_BonusData}
 */
export class Bonus {
  /**
   * @param {_BonusData} data
   */
  constructor(data) {
    ASSERT: {
      this.name = data.name;
      this.type = data.type;
      this.value = data.value;
      this.unique = data.unique;
      this.trigger_type = data.trigger_type;
      this.expires_at = data.expires_at;
    }

    Object.assign(this, data);
    this.expiresAt = data.expires_at ? new Date(data.expires_at) : null;
  }

  /**
   * Method name is misleading.
   * @todo Rename into `showCountdown`
   * @returns {boolean}
   */
  expires() {
    return !!this.expiresAt && this.trigger_type !== 'bonus_action' && (this.expiresAt.getTime() - now() <= 24 * 36e5);
  }

  hasExpired() {
    return this.expires() && (this.expiresAt?.getTime() ?? Infinity) <= now();
  }

  click() {
    inject('payment').overlay();
  }

  /**
   * @param {string} format
   */
  async getImageUrl(format) {
    //TODO: improve with next special
    // if (this.trigger_type == 'bonus_action') {
    //   return `${ASSET_PATH}${this.name}/payment/${format}_${locale.get()}.jpg`;
    // }
    const {default: getBonusBannerSrc} = await import(`${media.assets_ext}/bonus.js`);
    DEBUG: console.debug({name: this.name, format});
    DEBUG: console.debug(`${media.assets_ext}/${getBonusBannerSrc(this.name, format)}`);
    return `${media.assets_ext}/${getBonusBannerSrc(this.name, format)}`;
  }
}

/**
 * @typedef {import('../service/typeguard.js').BonusData} _BonusData
 */
