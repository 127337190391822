/**
 * @module Add Popupcontroller
 * @since 3.76.0
 * @author Jacob Viertel <jv@onscreen.net>
 *
 * Instantiate a `PopupController` here, so the element catches and shows popups.
 */

import {connectPopupController} from '@acng/frontend-discovery';
import {defineController} from '@acng/frontend-stargazer';

const MODULE = 'layout/config/controller/popup';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineController(':popup', (element, value) => {
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, ':popup', {element, value});

  connectPopupController(element, value);
});
