import angular from 'angular';
import {getLocale} from 'acng/locale/model/locale.js';

angular.module('payment').directive('onswPaymentNewWindow', [function () {
  /**
   * @type {angular.IDirective<angular.IScope & {
   *   locale: Function;
   *   toggleConsentForDataStorage: Function;
   * }>}
   */
  const directive = {
    require: '^^ngController',
    scope: {
      payment: '<',
      openAction: '&',
      resetAction: '&'
    },
    templateUrl: '/template/payment.newWindow',
    link: function (scope, element, attr, paymentController) {
      element.addClass('ons-form');
      scope.locale = getLocale;
      scope.toggleConsentForDataStorage = async () => {
        element.addClass('busy');
        // @ts-ignore refactor paymentController
        await paymentController.startTransaction();
        element.removeClass('busy');
      };
    }
  };

  return directive;
}]);
