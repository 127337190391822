import angular from 'angular';
import {publish} from "acng/core/context/event-bus.js";

angular.module('rating')
  .factory('Rating', ['http', 'bulk', 'user', '$q', function (http, bulk, user, $q) {

    function Rating(data) {}

    /**
     * @param {number} id
     * @param {"movie" | "Picture"} type
     */
    Rating.get = function (id, type) {
      if (user.guest) return $q.resolve(null);

      return bulk.get(id, 'api/rating/' + type).then(function (res) {
        return res.rating;
      }).catch(function () {
        return null;
      });

    };

    /**
     * @param {number} id
     * @param {"movie" | "Picture"} type
     * @param {number} rating
     */
    Rating.rate = function (id, type, rating) {
      return http().post('api/rating/' + type + '/' + id, $.param({ rating: rating }))
        .then(function (res) {
          return res.data.rating;
        });
    };

    return Rating;

  }]);
