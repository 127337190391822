angular.module('core')

  .directive('onswCountdown', ['$window', $window => {

    return {
      scope: {
        ts: '@',
        label: '@'
      },
      template: '<div class="box"><span class="label">{{label | translate}}</span><span class="value"></span></div>',
      link: (scope, element) => {

        var value = element.find('.value');
        let timeout;

        value.html(calc());
        run();

        function run() {
          timeout = $window.setTimeout(() => {
            value.html(calc());
            run();
          }, 1000);
        }

        function calc() {
          var distance = Math.max(0, scope.ts - Date.now());
          return (distance > 60 * 60 * 1000 ? Math.floor(distance / (1000 * 60 * 60)).toString().padStart(2, 0) + '<span>:</span>' : '') +
        Math.floor(distance % (1000 * 60 * 60) / (1000 * 60)).toString().padStart(2, 0) + '<span>:</span>' +
        Math.floor(distance % (1000 * 60) / 1000).toString().padStart(2, 0);
        }

        scope.$on('$destroy', () => $window.clearTimeout(timeout));
      }
    };
  }]);
