export const STATE_RESET = 'reset';
export const STATE_REQUESTED = 'requested';
export const STATE_PENDING = 'pending';
export const STATE_CONFIRMED = 'confirmed';
export const STATE_DENIED = 'denied';

export const POSSIBLE_STATES = /** @type {const} */ ([
  STATE_RESET,
  STATE_REQUESTED,
  STATE_PENDING,
  STATE_CONFIRMED,
  STATE_DENIED,
]);
