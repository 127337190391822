angular.module('payment')

  .directive('onswPaymentPackages', ['payment', payment => {

    let recommendPackage = null;

    return {
      scope: {
        products: '<',
        order: '@',
        methodId: '@?',
        selectPackage: '&',
        itemClass: '@'
      },
      template: `
<div class="box">
    <div class="package {{itemClass}}" ng-repeat="package in products | orderBy: order" ng-class="{'withBonus':package.bonus, 'packageSelected':recommend(package)}"
         ng-if="isAvailable(package)" ng-click="select(package)">
        <div ng-if="package.type === 'Coins'" class="box">
            <div class="ons-item bonus" ng-if="package.bonus">
              <span class="box">
                <span class="value">{{ percentValue(package) }}%</span>
                <span class="label" ng-bind="'payment.package.bonusBadge' | translate"></span>
              </span>
            </div>
            <div class="ons-item note" ng-if="!package.bonus && recommend(package)">
              <span class="box">
                <span class="label" ng-bind-html="'payment.package.note' | translate"></span>
              </span>
            </div>
            <div class="price">{{package.price | currency : package.currency[package.currency.symbol ? 'symbol' : 'iso_code'] : package._fractionSize}}</div>
            <span class="name" ng-if="!package.bonus" ng-bind-html="'payment.package.' + package.name + '.description' | translate"></span>
            <div class="value coins">
                <span class="default">{{package.value + package.addition}} Coins</span>
                <span class="bonus" ng-if="package.bonus">{{"payment.package.values.coins" | translate:'{coins:'+(package.value + package.bonus + package.addition)+'}'}}</span>
            </div>
        </div>
        <div ng-if="package.type === 'FreeCoins'" class="box freeCoins">
          <div class="box">
            <span class="ons-item button">
              <button class="box">
                <span class="label">{{'payment.redeemNow' | translate}}</span>
              </button>
            </span>
          </div>
        </div>
    </div>
</div>`,
      link: function (scope, element) {

        element.addClass('ons-layout ons-list');

        scope.isAvailable = function (pkg) {
          return !scope.methodId || pkg.methods[scope.methodId];
        };

        scope.$watch('products', products => {
          if (!Array.isArray(products) || products.includes(recommendPackage)) return;
          products.forEach(pkg => {
            if (pkg.preselected) {
              recommendPackage = pkg;
            }
          });
        });

        scope.select = function (pkg) {
          if (recommendPackage && pkg.value > recommendPackage.value) {
            recommendPackage = pkg;
          }
          scope.selectPackage({ package: pkg });
        };

        scope.recommend = function (pkg) {
          return recommendPackage?.id == pkg.id;
        };

        scope.percentValue = payment.calculateBonusPercentage;
      }
    };
  }]);
